export const VER_VERSION = 12;
export const VER_RELEASE = '2'; // (TEST)'; // (ONTWIKKEL)'; // (TEST)';
export const VER_BUILD   = '23.03.26';

export const APP_ID = 22;

export const WRI_GEEN                        = 0;
export const WRI_WORDT_AANGEMAAKT            = 1;
export const WRI_PRIJSOPGAVE                 = 2;
export const WRI_BESCHIKBAAR_VOOR_UITVOERING = 3;
export const WRI_WORDT_UITGEVOERD            = 4;
export const WRI_IS_UITGEVOERD               = 5;
export const WRI_BESCHIKBAAR_VOOR_VERWERKING = 6;
export const WRI_IS_VERWERKT                 = 7;
export const WRI_GEREED                      = 8;
export const WRI_AFGEHANDELD                 = 9;
