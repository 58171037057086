import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { NavController, AlertController } from '@ionic/angular';

import { Plugins } from '@capacitor/core';

import { BehaviorSubject, from } from 'rxjs';
import { map, tap, take, switchMap } from 'rxjs/operators';

import * as Constants from '../shared/constants';
import { User } from './user.model';
import { ErrorAPI } from './auth.model';

interface LogInResponseData {
  data?: {
    session: {
      key: string;
    },
    user: {
      id: number,
      name: string;
      initials: string;
      relation: {
        number: string;
      }
    }
  };
  error?: {
    code: number;
    message: string;
    exception?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // tslint:disable-next-line: variable-name
  private _user = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private navCtrl: NavController,
    private alertCtrl: AlertController
  ) { }

  get userIsAuthenticated() {
    return this._user.asObservable()
      .pipe(
        map(user => {
          if (user) {
            return !!user.token;
          } else {
            return false;
          }
        })
      );
  }

  get userId() {
    return this._user.asObservable()
      .pipe(
        map(user => {
          if (user) {
            return user.id;
          } else {
            return null;
          }
        })
      );
  }

  get user() {
    return this._user.asObservable();
  }

  autoLogin() {
    return from(Plugins.Storage.get({ key: 'authData' }))
      .pipe(
        map(storedData => {
          if (!storedData || !storedData.value) {
            return null;
          }
          const parsedData = JSON.parse(storedData.value) as {
            host: string,
            port: number,
            https: boolean,
            id: number,
            name: string,
            initials?: string,
            token: string,
            appDate: Date,
            mode: string
          };
          const user = new User(
            parsedData.host,
            parsedData.port,
            parsedData.https ? 'https' : 'http',
            parsedData.id,
            parsedData.name,
            parsedData.initials,
            parsedData.token,
            new Date(parsedData.appDate),
            parsedData.mode
          );
          return user;
        }),
        tap(user => {
          if (user) {
            this._user.next(user);
          }
        }),
        map(user => {
          return !!user;
        })
      );
  }

  login(host: string, port: number, protocol: string, email: string, password: string) {
    return this.http.get<LogInResponseData>(
      `${protocol}://${host}:${port}/odapi/rest/TMethods/logIn/${Constants.APP_ID}/${email}/${password}/json`
    ).pipe(
      tap(userData => {
        if (userData.data) {
          this._user.next(
            new User(
              host,
              port,
              protocol,
              userData.data.user.id,
              userData.data.user.name,
              userData.data.user.initials,
              userData.data.session.key,
              new Date(),
              'all'
            )
          );
          this.storeAuthData(
            host,
            port,
            protocol === 'https',
            userData.data.user.id,
            userData.data.user.name,
            userData.data.user.initials,
            userData.data.session.key,
            new Date(),
            'all'
          );
        } else {
          this._user.next(null);
        }
      })
    );
  }

  storeAuthData(host: string, port: number, https: boolean, id: number, name: string, initials: string, token: string, appDate: Date, mode: string) {
    const data = JSON.stringify(
      {
        host,
        port,
        https,
        id,
        name,
        initials,
        token,
        appDate,
        mode
      }
    );
    Plugins.Storage.set(
      {
        key: 'authData',
        value: data
      }
    );
  }

  logout() {
    return this.user
      .pipe(
        take(1),
        switchMap
          (user => {
            if (!user) {
              return;
            }
            return this.http
              .get(`${user.protocol}://${user.host}:${user.port}/odapi/rest/TMethods/logOut/${user.token}/json`);
          }),
        tap(resData => {
          this._user.next(null);
          Plugins.Storage.remove({ key: 'authData' });
        })
      );
  }

  private gotoDate(incDays: number) {
    return this.user
      .pipe(
        take(1),
        map
          (user => {
            if (!user) {
              return;
            }
            let newDate: Date;
            if (incDays === 0) {
              newDate = new Date();
            } else {
              newDate = new Date(user.appDate.getTime() + incDays * 24 * 60 * 60 * 1000);
            }
            this._user.next(new User(
              user.host,
              user.port,
              user.protocol,
              user.id,
              user.name,
              user.initials,
              user.token,
              newDate,
              user.mode
            ));
            this.storeAuthData(
              user.host,
              user.port,
              user.protocol === 'https',
              user.id,
              user.name,
              user.initials,
              user.token,
              newDate,
              user.mode
            );
          })
      );

  }

  today() {
    return this.gotoDate(0);
  }

  nextDate() {
    return this.gotoDate(+1);
  }

  priorDate() {
    return this.gotoDate(-1);
  }

  public setMode(mode: string) {
    return this.user
    .pipe(
        take(1),
        map
        (user => {
          if (!user) {
            return;
          }
          this._user.next(new User(
              user.host,
              user.port,
              user.protocol,
              user.id,
              user.name,
              user.initials,
              user.token,
              new Date(user.appDate),
              mode
            ));
            this.storeAuthData(
              user.host,
              user.port,
              user.protocol === 'https',
              user.id,
              user.name,
              user.initials,
              user.token,
              new Date(user.appDate),
              mode
            );
          })
      );
  }

  private showAlert(header: string, message: string) {
    this.alertCtrl.create(
      {
        header,
        subHeader: 'Log opnieuw in',
        message,
        buttons: ['Sluiten']
      }
    ).then(alertEl => alertEl.present());
  }

  public handleErrorHttp(error) {
    // Write code to console log
    console.log('Http error: ',error.message)
    // Log out 
    this.logout().subscribe();
    // Inform user
    this.showAlert('Er is een verbindingsfout opgetreden', error.name + ', ' + error.statusText);
    // Return to login page
    this.navCtrl.navigateBack('/auth');
    return;
  }

  public handleErrorAPI(errorAPI: ErrorAPI) {
    // Write code to console log
    console.log('API error: ',errorAPI.code)
    // Log out 
    this.logout().subscribe();
    // Inform user
    this.showAlert('Er is een applicatiefout opgetreden', errorAPI.message + (errorAPI.exception ? ': ' + errorAPI.exception : ''));
    // Return to login page
    this.navCtrl.navigateBack('/auth');
    return;
  }

}
