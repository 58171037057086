export class User {
    constructor(
        public host: string,
        public port: number,
        public protocol: string,
        public id: number,
        public name: string,
        public initials: string,
        public token: string,
        public appDate: Date,
        public mode: string
    ) {}

    dateStr(): string {
        let d: number;
        let m: number;
        let y: number;
        if (this.appDate) {
            d = this.appDate.getDate();
            m = this.appDate.getMonth() + 1; // Month from 0 to 11
            y = this.appDate.getFullYear();
        } else {
            const newDate = new Date();
            d = newDate.getDate();
            m = newDate.getMonth() + 1; // Month from 0 to 11
            y = newDate.getFullYear();
        }
        return '' + y + (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d);
    }

    public url(methodName: string, params: string[], queryParams: string[]): string {

        // base URL:
        let result = `${this.protocol}://${this.host}:${this.port}/odapi/rest/TMethods/${methodName}/${this.token}`;

        // parameters ?
        for (const param of params) {
            if (param === 'id') {
                result = `${result}/${this.id}`;
            } else if (param === 'date') {
                result = `${result}/${this.dateStr()}`;
            } else {
                result = `${result}/${param}`;
            }
        }

        // query parameters ?
        if (queryParams.length > 0) {
            for (let i = 0; i < queryParams.length; i++) {
                    result = `${result}${i === 0 ? '?' : '&'}${queryParams[i]}`;
                }
            }
        return result;
    }

    public dateString(d : Date): string {
        return d.getFullYear().toString() + 
               (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1).toString() +
               (d.getDate() < 10 ? '0': '') + d.getDate().toString();
    }

    public timeString(d : Date): string {
        return (d.getHours() < 10 ? '0' : '') + d.getHours().toString() +
               ':' + 
               (d.getMinutes() < 10 ? '0' : '') + d.getMinutes().toString();
    }

    public dateTimeString(d : Date): string {
        return d.getFullYear().toString() + 
               (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1).toString() +
               (d.getDate() < 10 ? '0': '') + d.getDate().toString() +
               (d.getHours() < 10 ? '0': '') + d.getHours().toString() +
               (d.getMinutes() < 10 ? '0': '') + d.getMinutes().toString() +
               (d.getSeconds() < 10 ? '0': '') + d.getSeconds().toString();
    }

}
