import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Platform, LoadingController } from '@ionic/angular';
import { Capacitor, Plugins } from '@capacitor/core';

import { Subscription } from 'rxjs';

import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private authSub: Subscription;
  private userSub: Subscription;
  private previousAuthState = false;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar
    private authService: AuthService,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });
  }

  ngOnInit() {
    // this.typeSub = this.authService.userType.subscribe(type => this.userType = type);
    this.userSub = this.authService.user.subscribe(user => {
      if (user) {
        // this.userType = user.type;
        // this.preliminaryCustomerOrdersName = user.preliminaryCustomerOrdersName;
        // this.preliminaryCustomerOrdersValue = user.preliminaryCustomerOrdersValue;
      }
    }
    );

    this.authSub = this.authService.userIsAuthenticated.subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
        this.router.navigateByUrl('/auth');
      }
      this.previousAuthState = isAuth;
    });
  }

  onLogout() {
    this.loadingCtrl
      .create({
        keyboardClose: true,
        message: 'Uitloggen...'
      })
      .then(loadingEl => {
        loadingEl.present();
        this.authService.logout()
          .subscribe(resData => {
            loadingEl.dismiss();
          });
      });
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    };
    // if (this.typeSub) {
    //   this.typeSub.unsubscribe();
    // }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

}
