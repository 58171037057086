import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'day',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
  },    
  {
    path: 'day',
    loadChildren: () => import('./day/day.module').then( m => m.DayPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'outstanding',
    loadChildren: () => import('./outstanding/outstanding.module').then( m => m.OutstandingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'work-order',
    children: [
      {
        path: ':id',
        loadChildren: () => import('./work-orders/work-order-detail/work-order-detail.module').then(m => m.WorkOrderDetailPageModule),
        canLoad: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
